import { useState, useEffect } from 'react';
import { dashboard_nav } from '../../../data/dashboard'
import './dashboardnav.css'
import { Link } from 'react-router-dom';
import {  BsBoxArrowLeft, BsGraphUp, BsSearch, BsBoxArrowUpRight } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import logo from '../../../Images/dashboard/logo-green.png';
const DashboardNav = () => {
 
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClick = () => {
    window.open('https://analytics.google.com/', '_blank');
  };
 const  handleClickConsole   = () => {
    window.open('https://search.google.com/search-console/welcome', '_blank');
  };
  const handleLogout = () => {
  
    localStorage.removeItem('userToken'); 

    // Redirect to login page
    setRedirectTo('/admin-login');
  };
  return (
    <nav className='dashboard-nav position-sticky '>
      <div className="container pe-5 "> 
        <div className="row">
        <div className=" py-sm-4 pt-5 ps-sm-4">
        <img src={logo} alt="" />
        </div>
            {dashboard_nav.map((item)=>(
            <Link className="d-flex gap-3 py-2 items semibold ps-4" role='button'  
            to={item.onclick}>
            <i className='semibold'>{item.icon}</i>
            <h6 className='my-auto'>{item.text}</h6>
            </Link>
            ))}  
              <Link className="d-flex gap-3 py-2 items semibold ps-4" role='button' 
         onClick={handleClick}
           >
            <i className='semibold'><BsGraphUp/></i>
            <h6 className='my-auto'>Analytics</h6>
            <i className='semibold'><BsBoxArrowUpRight/></i>
            </Link>
            <Link className="d-flex gap-3 py-2 items semibold ps-4" role='button' 
           onClick={handleClickConsole}
           >
            <i className='semibold'><BsSearch/></i>
    <h6 className='my-auto'>Search Console</h6>
            <i className='semibold'><BsBoxArrowUpRight/></i> 
        
            </Link>
            <Link className="d-flex gap-3 py-3 items semibold ps-4" role='button' 
         onClick={handleLogout}
           >
            <i className='semibold'><BsBoxArrowLeft/></i>
            <h6 className='my-auto'>Logout</h6>
            </Link>
        </div>
      
      </div>
    </nav>
  )
}

export default DashboardNav

import React from "react";
import DashboardNav from "../dashboard-nav/DashboardNav";
 
import './dashboardhome.css'
import NavX from "../dashboard-nav/NavX";
import RecentEnquiries from "./RecentEnquiries";
import RecentBlogs from "./RecentBlogs";
const DashboardHome = () => {

  return <> 
<section className='d-flex flex-row admin-home'>
  <DashboardNav/>
  <div className='content-section section-padding '>
    <NavX/>
    <div className="row  ms-3 d-flex flex-row neg-mt width-80">
      <div className="col-md-7  ">
<RecentBlogs/>
       </div>
      <div className="col-md-4">
        <RecentEnquiries/>
       </div>
     </div>
</div>
</section>
  </>;
};

export default DashboardHome;

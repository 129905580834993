import React, { useState } from "react";
import axios from "axios";
import './admin-login.css'
import { adminlogin } from "../../data/admin-login";
const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        username,
        password,
      });
      if (response.data.success) {
        localStorage.setItem('authToken', response.data.authToken);
        window.location = "/verifyotp/EtyKs46l614Y";
      } else {
        setError("Invalid credentials");
      }
    } catch (error) {
      console.log(error);
      setError("Something went wrong");
    }
  };
  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };
  return (
   <>
   {adminlogin.map((item)=>(
    <section className="admin-login" 
    style={{
      backgroundImage: `url(${item.background})`,
      alt: `{item.alt}`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}>    
    <div className="container p-3 p-md-5 login-card-background">
    <div className=" login-card mx-auto ">
  
     <img src={item.logo} alt="" className="mx-auto pb-3 pb-md-4"/> 
      <form onSubmit={handleLogin} className="mx-auto w-100">
        <div>
          <input
            type="text"
            id="username"
            className="p-md-3 p-2 mb-md-4 mb-3 w-100"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Password"
            className="p-md-3 p-2 mb-4 w-100"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
           
        </div>
        <div>
        <input
          type="checkbox"
          id="rememberMe"
          className=" mb-4 me-2 checkbox"
          checked={rememberMe}
          onChange={handleRememberMe}
        />
        <label htmlFor="rememberMe">Remember me</label>
      </div>
        <button type="submit " className="w-100 login-button p-2">Login</button>
        {error && <p className="mt-2 bold">{error}</p>}
      </form>
    </div> 
    </div>
    </section>
     ))}
    </>
  );
};
export default AdminLogin;

import { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardNav from '../dashboard-nav/DashboardNav'
import {  editdelete } from '../../../data/authors'
import { Link } from 'react-router-dom';
import { BsTrash3, BsPencilSquare } from "react-icons/bs";
import NavX from '../dashboard-nav/NavX';

const AuthorsHome = () => {
  const [authors, setAuthors] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/authors`)
      .then(res => {
        setAuthors(res.data);
      })
      .catch(err => console.log(err));
  }, []);
  const handleDeleteAuthor = (authorId) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/api/authors/${authorId}`)
      .then(res => {
        alert('Author deleted successfully!');
        setAuthors(authors.filter(author => author._id !== authorId));
      })
      .catch(err => console.log(err));
  };

  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClick = () => {
    setRedirectTo("/adminpanel/add-author");
  };

  return (
<section className='d-flex flex-row authors-home'>
  <DashboardNav/>
  <div className='content-section section-padding'>
    <NavX/>
  <div className="row mx-2  neg-mt width-80">
    
  {authors.map(author => (
<div className="col-12 col-lg-6 py-3" key={author._id}>
<div className="author-card py-3 box-shadow-admin" >
<div className="d-flex p-1 p-md-2 justify-content-between mx-2">
  <div className='d-flex flex-md-row  flex-col '>
 <div>
  <img src={`${process.env.REACT_APP_API_URL}/uploads/${author.profilePicture}`} alt={author.name} /></div>
  <div className='my-auto ms-md-4 ms-2 '>
    <h6 className="bold  ">{author.name}</h6>
  <h6 className="semibold">{author.designation}</h6> 
  </div>
  </div>
  <div className="d-flex my-auto">
    {editdelete.map((item)=>(
      <>
      <i role='button' className='me-3 delete-button'
      onClick={() => handleDeleteAuthor(author._id)}>
        <BsTrash3 />
      </i>
      <i role='button' className='me-2 edit-button' >
      <Link to={`/adminpanel/update-author/${author._id}`} 
      className='me-2 edit-button ' style={{color:"black"}}>
        <BsPencilSquare />
        </Link>
        </i>
      </>
    ))}
  </div>
  </div>
</div>
  </div>
  ))}
<button className='add-author-button p-2' onClick={handleClick}>
   Add new author 
    </button>
   
  </div>
</div>
 </section>
  )
}

export default AuthorsHome

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BsArrowRightShort  } from "react-icons/bs";
import { useMediaQuery } from 'react-responsive';

const RecentEnquiries = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [redirectTo, setRedirectTo] = useState(null);
  const isLargeScreen = useMediaQuery({ minWidth: 1400 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1399 });
 
  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  

  const handleClickOpenRequest = () => {
    setRedirectTo("/adminpanel/enquiries");
  };
useEffect(() => {
  const fetchEnquiries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contact/recent`);
      setEnquiries(response.data);
    } catch (error) {
      console.error('Failed to fetch enquiries:', error);
    }
  };

  fetchEnquiries();
}, []);
const truncateText = (text, limit) => {
  if (text.length > limit) {
    return text.substring(0, limit) + '...';
  }
  return text;
};

let titleCharLimit;
if (isLargeScreen) {
  titleCharLimit = 80; 
} else if (isMediumScreen) {
  titleCharLimit = 60;  
}  
  return (
    <div className='recent-card box-shadow-admin p-4 h-100'>
<div className="d-flex justify-content-between">
<h6>Requests</h6>
<h6 onClick={handleClickOpenRequest}  role='button'><BsArrowRightShort /></h6>
</div>
<div>
                            {enquiries.length === 0 ? (
                                  <p>No recent enquiries.</p>
                               ) : (
                                  enquiries.map((enquiry, index) => (
                                   <div key={index} className='my-2  p-1'>
                                <h6 className='text-dasGrayDark font-bold' >{enquiry.name}</h6>
                                <p className='text-dasGrayDark'>  {truncateText(enquiry.message, titleCharLimit)} </p>
                                </div>
                                  ))
                               )}
                            </div>

     </div>
  )
}

export default RecentEnquiries

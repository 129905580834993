import { BsTrash3, BsPencilSquare } from "react-icons/bs";

export const authors=[
    {
name:"Mohammad Hashemi",
designation:"UX/UI designer",
image:"./Images/authors/ui:ux.png"
},
{
    name:"Mohammad Hashemi",
    designation:"Sales Executive",
    image:"./Images/authors/sales.png"
    },
    {
        name:"Mohammad Hashemi",
        designation:"Graphic designer",
        image:"./Images/authors/graphic.png"
    }
]
export const editdelete = [{
    delete:<BsTrash3/>,
    edit:<BsPencilSquare/>
}]
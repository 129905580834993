import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
const DisplayOneBlog = ({match}) => {
  const [post, setPost] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
      setPost(data);
    };
    fetchPost();
  }, [id]);

  //date showing
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };
  return (
    <div>
      <h1>{post.title}</h1>
      <p>{post.mainParagraph}</p>
      <p>Created on: {formatDate(post.createdAt)}</p>
      {/* Render the rest of the post content here */}
    </div>
  );
};


export default DisplayOneBlog

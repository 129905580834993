import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import NavX from '../dashboard-nav/NavX';
import DashboardNav from '../dashboard-nav/DashboardNav';
import './bloghome.css'
import { BsSend } from "react-icons/bs";
import { BsTrash3, BsPencilSquare } from "react-icons/bs";
import { useMediaQuery } from 'react-responsive';

const BlogsHome = () => {
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState('');
  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    const fetchPosts = async () => {
      let url = `${process.env.REACT_APP_API_URL}/api/posts`;
      if (category) {
        url = `${process.env.REACT_APP_API_URL}/api/posts/category?category=${category}`;
      }
      const { data } = await axios.get(url);
      setPosts(data);
    };
    fetchPosts();
  }, [category]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
        setPosts(posts.filter((post) => post._id !== id));
        alert('Post deleted successfully!');
      } catch (error) {
        console.error(error);
        alert('Failed to delete the post.');
      }
    }
  };
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClickOpen = () => {
    setRedirectTo("/adminpanel/blogs/add-blogpost");
  };
//display catergory
  const getCategoryName = (category) => {
    const categories = {
      seo: 'Search Engine Optimization',
      ppc: 'Pay-per-click Advertising',
      email: 'Email Marketing',
      digital: 'Digital Strategy',
      graphic: 'Graphic Design',
      web: 'Web Design & Development',
      content: 'Content Marketing',
      display: 'Display & Video Advertising',
      social: 'Social Media Marketing',
    };
    return categories[category] || 'Unknown';
  };
  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };
 
  let titleCharLimit;
  if (isLargeScreen) {
    titleCharLimit = 25; 
  } else if (isMediumScreen) {
    titleCharLimit = 20;  
  } else if (isSmallScreen) {
    titleCharLimit = 18; 
  }
  return (
    <section className='d-flex flex-row admin-home'>
  <DashboardNav/>
  <div className='content-section section-padding   '>
    <NavX/>
    <div className="blog-cards ">
        <select id="category" name="category" className='ms-5 p-2 category-select' onChange={handleCategoryChange}>
          <option value="">All</option>
          <option value="seo">Search Engine Optimization </option>
          <option value="ppc">Pay-per-click Advertising </option>
          <option value="email">Email Marketing </option>
          <option value="digital">Digital Strategy </option>
          <option value="graphic">Graphic Design</option>
          <option value="web">Web Design & Development</option>
          <option value="content">Content Marketing</option>
          <option value="display">Display & Video Advertising</option>
          <option value="social">Social Media Marketing</option>
        </select>
        <div className='row px-5'>
      {posts.map((post) => (
       <div key={post._id} className='col-12 col-sm-6 col-md-4 mb-4' >
       {/* Render the thumbnail image and other post details here */}
      <div className='blog-home-card h-100 box-shadow-admin'>
        <div className="thumbnail ">
          <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} />
        </div>
        <div className='p-3' >
        <h6 className='bold py-2'>{truncateText(post.title, titleCharLimit)}</h6>
        <p>Created on: {formatDate(post.createdAt)}</p>
        <div className="d-flex justify-content-between pb-3">
          <p style={{color:"#119f93"}} className='my-auto'>{getCategoryName(post.category)}</p>
          <div className='d-flex flex-row my-auto edit-button gap-2'>
            <button onClick={() => handleDelete(post._id)}> <BsTrash3 /></button>
            <Link to={`/adminpanel/blogs/${post._id}/edit`}>
            <button><BsPencilSquare/></button>
            </Link>
          </div>
        </div>
        </div>
      </div>
    </div>
  ))}
</div>

    <button type="submit" className=" add-blogpost p-2" onClick={handleClickOpen}>
       <BsSend className='me-2' /> Write an article
       </button>
       </div>
    </div>
    </section>
  );
};

export default BlogsHome;

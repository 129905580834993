import React from 'react'
import './dashboardnav.css'
const NavX = () => {
  return (
    <div>
    <nav className='navbar-x position-sticky mt-0'>
<div className="row ">
    <div className="d-flex flex-column p-3  ">
        <h6  >Hello Trustline!</h6>
        <h6 className="bold">Welcome back</h6>
    </div>
</div>
    </nav>
    </div>
  )
}
export default NavX

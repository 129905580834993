import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/offcanvas";
import AdminLogin from "./pages/login/AdminLogin";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
 import DashboardHome from "./pages/dashboard/dashboard-home/DashboardHome";
import AddAuthor from "./pages/dashboard/dashboard-authors/AddAuthor";
import BlogsHome from "./pages/dashboard/dashboard-blogs/BlogsHome";
import AddBlogPost from "./pages/dashboard/dashboard-blogs/AddBlogPost";
 import EditAuthor from "./pages/dashboard/dashboard-authors/EditAuthor";
import DeleteAuthor from "./pages/dashboard/dashboard-authors/DeleteAuthor";
import DisplayOneBlog from "./pages/dashboard/dashboard-blogs/DisplayOneBlog";
 import DeleteBlogPost from "./pages/dashboard/dashboard-blogs/DeleteBlogPost";
 import AuthorsHome from "./pages/dashboard/dashboard-authors/AuthorsHome";
  import EnquiriesHome from "./pages/dashboard/dashboard-enquiries/EnquiriesHome";
 import React from "react";
import OtpVerify from "./pages/login/OtpVerify";
import EditBlogPost from "./pages/dashboard/dashboard-blogs/EditBlog";
function App() {
  const isAuthenticated = !!localStorage.getItem('authToken');

  console.log('Is Authenticated:', isAuthenticated);  
  return (
    <Router>
      <Routes> 
      <Route path="/admin-login" element= {<AdminLogin   />} />
      <Route path="/verifyotp/EtyKs46l614Y" element= {<OtpVerify   />} />
          <Route path="/adminpanel" element={isAuthenticated ? <DashboardHome /> : <AdminLogin />} />
          <Route path="/adminpanel/authors" element={isAuthenticated ? <AuthorsHome /> : <AdminLogin />} />
          <Route path="/adminpanel/add-author" element={isAuthenticated ? <AddAuthor /> : <AdminLogin />} />
          <Route path="/adminpanel/update-author/:id" element={isAuthenticated ? <EditAuthor /> : <AdminLogin />} />
          <Route path="/adminpanel/delete-author/:id" element={isAuthenticated ? <DeleteAuthor /> : <AdminLogin />} />
        
        {/**blogs route */}
        <Route path="/adminpanel/blogs"  element={isAuthenticated ? <BlogsHome /> : <AdminLogin />} />
        <Route path="/adminpanel/blogs/add-blogpost"  element={isAuthenticated ? <AddBlogPost />: <AdminLogin />} />
        <Route path="/adminpanel/blogs/:id/edit"  element={isAuthenticated ? <EditBlogPost />: <AdminLogin />} />
        <Route path="/adminpanel/blogs/:id/delete" element={isAuthenticated ? <DeleteBlogPost />: <AdminLogin />} />
         <Route path="/blogs/article/:id" element={<DisplayOneBlog />} />

        {/**contcat  */}
          <Route path="/adminpanel/enquiries"  element={isAuthenticated ? <EnquiriesHome/>: <AdminLogin />} />
      </Routes>
    </Router>
  );
}

export default App;

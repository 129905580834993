import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardNav from '../dashboard-nav/DashboardNav';
import NavX from '../dashboard-nav/NavX';
import img from '../../../Images/dashboard/blogs-home/one image selector.svg'
import img3 from '../../../Images/dashboard/blogs-home/three image selector.svg'
import {BsPlusLg} from 'react-icons/bs'
import { useParams } from 'react-router-dom';
import { BsExclamationTriangle } from "react-icons/bs";
 
const EditBlogPost = ( ) => {
  let { id } = useParams();
  const [imageSelected, setImageSelected] = useState(false);
  const [imageSelectedthree, setImageSelectedthree] = useState(false);
  const [title, setTitle] = useState('');
  const [mainParagraph, setMainParagraph] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [subParagraph, setSubParagraph] = useState('');
  const [mainBanner, setMainBanner] = useState(null);
  const [images, setImages] = useState([]);
  const [altText, setAltText] = useState('');
  const [altTextSecondary, setAltTextSecondary] = useState('');
  const [category, setCategory] = useState('seo');  
  const [authorId, setAuthorId] = useState('');
  const [authors, setAuthors] = useState([]);
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription,  setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [sections, setSections] = useState([{ subTitle: '', paragraph: '', sectionImage: null, youtubeLink: '' }]);
  const [redirectTo, setRedirectTo] = useState(null);
  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClickOpen = () => {
    setRedirectTo("/adminpanel/blogs");
  };
  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
        setTitle(response.data.title);
        setMainParagraph(response.data.mainParagraph);
        setSubTitle(response.data.subTitle);
        setMainBanner(response.data.mainBanner);
        setImages(response.data.images);
        setAltText(response.data.altText);
        setSubParagraph(response.data.subParagraph);
        setAltTextSecondary(response.data.altTextSecondary);
        setCategory(response.data.category);  
        setMetaTitle(response.data.metaTitle);
        setMetaDescription(response.data.metaDescription);
        setMetaKeywords(response.data.metaKeywords);
        setImageSelected(response.data.imageSelected); 
        setImageSelectedthree(response.data.imageSelectedthree); 
        setAuthorId(response.data.authorId); 
        setSections(response.data.sections || [])
      } catch (err) {
        console.error(err);
      }
    };
    fetchBlogPost();
  }, [ id]); // Depende
   //author
   useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/authors`);
        setAuthors(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchAuthors();
  }, []);
  
//form
  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'title':
        setTitle(value);
        break;
      case 'mainParagraph':
        setMainParagraph(value);
        break;
      case 'subTitle':
        setSubTitle(value);
        break;
         
      case 'subParagraph':
        setSubParagraph(value);
        break;
      case 'altText':
        setAltText(value);
        break;
        case 'altTextSecondary':
        setAltTextSecondary(value);
        break;
        case 'category':
        setCategory(value); // update the category value
        break;
        case 'metaTitle':
       setMetaTitle(value);
        break;
      case 'metaDescription':
        setMetaDescription(value);
        break;
      case 'metaKeywords':
        setMetaKeywords(value);
        break;
          case 'sectionTitle':
              setSections([
                {
                  subTitle: value,   // Change this to 'subTitle'
                  paragraph: sections[0].paragraph // Preserve the existing value of `paragraph`
                }
              ]);
              break;
          case 'sectionBody':
              setSections([
                {
                  subTitle: sections[0].subTitle, // Preserve the existing value of `subTitle`
                  paragraph: value  // Change this to 'paragraph'
                }
              ]);
              break;
        default:
        break;
    }
  };

  const handleSectionChange = (event, index) => {
    const { name, value } = event.target;
    setSections(prevSections => prevSections.map((section, i) => {
      if (i !== index) return section;
      return { ...section, [name]: value };
    }));
  };
  
  const handleSectionImageChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      setSections(prevSections => prevSections.map((section, i) => {
        if (i !== index) return section;
        return { ...section, sectionImage: file, sectionImageFieldname: `sections[${index}][sectionImage]` };
      }));
    }
  };
  const handleAddMore = () => {
    setSections([...sections, { subTitle: '', paragraph: '', sectionImage: null }]);
  };

  const handleMainBannerChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageSelected(true);  // update imageSelected state
      setMainBanner(file);
    }
  };
  
  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      setImages([...event.target.files]);
      setImageSelectedthree(event.target.files.length > 0);
    }
  };
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setMainBanner(file || '');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('mainParagraph', mainParagraph);
    formData.append('subTitle', subTitle);
     formData.append('subParagraph', subParagraph);
    formData.append('main_banner', mainBanner);
    formData.append('altText', altText);
    formData.append('altTextSecondary', altTextSecondary);
    formData.append('category', category); // add category to formData
    formData.append('authorId', authorId);
    formData.append('metaTitle', metaTitle);
    formData.append('metaDescription', metaDescription);
    formData.append('metaKeywords', metaKeywords);
 

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].sectionImage) {
        formData.append(`sections[${i}][sectionImage]`, sections[i].sectionImage);
        // Exclude sectionImage from the JSON stringified section
        const {sectionImage, ...sectionWithoutImage} = sections[i];
        formData.append(`sections[${i}]`, JSON.stringify(sectionWithoutImage));
      } else {
        formData.append(`sections[${i}]`, JSON.stringify(sections[i]));
      }
    }
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/posts/${id}`, formData, {  
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
        alert('Blog Post updated successfully!');
      setTitle('');
      setMainParagraph('');
      setSubTitle('');
       setSubParagraph('');
      setMainBanner('');
      setImages([]);
      setAltText('');
      setAltTextSecondary('');
      setCategory('seo');  
      setMetaTitle('');
      setMetaDescription('');
      setMetaKeywords('');
      setImageSelected(false); 
      setImageSelectedthree(false); 
    } catch (err) {
      console.error(err);
      console.error(err.response.data); // Log the detailed error response from the server
      alert('Error updating post');
    }
  };
  const handleCancel = () => {
    window.location.href = '/adminpanel/blogs';
  };
  return (
    <>
    
  <section className='d-flex flex-row authors-home  '>
  <DashboardNav/>
  <div className='content-section section-padding  '>
    
    <NavX/>
    
    <div className="warning-box p-2 position-absolute"  >
    <div className=" ">
      <div className="d-flex flex-row justify-content-between">
      <h4 className='text-dasBlack text-lg font-bold'>Edit mode</h4>
      <h4 style={{color:"#FF0A1C"}}><  BsExclamationTriangle /></h4>
      </div>
     
<p className='mt-2' style={{color:"#FF0A1C"}}>
  Attention: Please re-upload the blog post images & select the author and category.</p>
</div>
    </div>

  <div className=" add-blog-card ">
  <div className="container">
      <form   className="mx-auto pt-4 w-50" onSubmit={handleSubmit} >
      <h6 className='pb-3' style={{fontWeight:"800",  color:"#119f93"}}>Heading</h6>

     <select  name="category" value={category} onChange={handleChange}
      className='p-md-3 p-2 mb-4 w-100'>
    <option  >Select Category</option>
    <option value="seo">Search Engine Optimization </option>
    <option value="ppc">Pay-per-click Advertising </option>
    <option value="email">Email Marketing </option>
    <option value="digital">Digital Strategy </option>
    <option value="graphic">Graphic Design</option>
    <option value="web">Web Design & Development</option>
    <option value="content">Content Marketing</option>
    <option value="display">Display & Video Advertising</option>
    <option value="social">Social Media Marketing</option>
  </select>

  <select name="author"  className='p-md-3 p-2 mb-4 w-100'
  value={authorId} onChange={(event) => setAuthorId(event.target.value)}>
  <option value="">Select an author</option>
  {authors.map((author) => (
    <option key={author._id} value={author._id}>{author.name}</option>
  ))}
</select>
        <div className=''>
          <input
           type="text" name="title" value={title} onChange={handleChange}
            className="p-md-3 p-2 w-100  mb-md-4 mb-3"
            placeholder="Title *"     
            required
          />
        </div>
        <div>
        {!imageSelected && (
        <img 
    src={img}
    className="p-md-3 p-2 w-100  mb-md-4 mb-3"
    alt='Digital Marketing'
  />
  )}
  <input 
    type='file' 
    id='fileInput' 
    name="main_banner" 
    className="p-md-3 p-2 w-100  mb-md-1 mb-1"
    required  
    onChange={handleMainBannerChange}
    />
   <small  style={{color:"#808080"}}>Recommended image size: 1200px * 700px (ratio 16:9)</small>

       </div>
        <div className='mt-3'>
          <input
            placeholder="Alt text *"
            className="p-md-3 p-2 mb-4 w-100  "
            required
            type="text" name="altText" value={altText} onChange={handleChange}
          />
        </div>
        <div>
  <input
    placeholder="Meta Title"
    className="p-md-3 p-2 mb-4 w-100  "
    type="text" name="metaTitle" value={metaTitle} onChange={handleChange}
  />
</div>
<div>
  <textarea
    placeholder="Meta Description"
    className="p-md-3 p-2 mb-4 w-100  "
    type="text" name="metaDescription" value={metaDescription} onChange={handleChange}
  />
</div>
<div>
  <input
    placeholder="Meta Keywords"
    className="p-md-3 p-2 mb-4 w-100  "
    type="text" name="metaKeywords" value={metaKeywords} onChange={handleChange}
  />
</div>
         {/**body */}
         <h6 className='pb-3' style={{fontWeight:"800",  color:"#119f93"}}>Body</h6>
        <div>
          <textarea  placeholder='Main paragraph *' 
          required
           className='p-md-3 p-2 mb-4 w-100' name="mainParagraph" value={mainParagraph} onChange={handleChange}/>
        </div>
<div>
</div>
<div>
{!imageSelectedthree && (
<img 
    src={img3}
    className="p-md-3 p-2 w-100  mb-md-4 mb-3"
    alt='Digital Marketing'
  />
  )}
<input  placeholder='Banner'  className="py-md-3 px-md-1 px-0 p-2 mb-1 w-100" type="file" name="images" multiple onChange={handleImageChange}   />
</div>
<small className=' ' style={{color:"#808080"}}>Choose 3 images for Instagram - Recommended image size: 1080px * 1080px (ratio 1:1)</small>

<div >
          <input
            placeholder="Alt text for images"
            className="p-md-3 p-2 mb-4 w-100 mt-3 "
            type="text" name="altTextSecondary" value={altTextSecondary} onChange={handleChange}
          />
        </div>
      
        {
  sections.map((section, index) => (
    <div key={index}>
      <input
        type="text"
        name="subTitle"
        placeholder='subtitle'
        className='p-md-3 p-2 mb-4 w-100 mt-3'
        value={section.subTitle}
        onChange={(event) => handleSectionChange(event, index)}
      />
      <textarea
        name="paragraph"
        placeholder='paragraph'
        value={section.paragraph}
        className='p-md-3 p-2 mb-4 w-100 '
        onChange={(event) => handleSectionChange(event, index)}
      />
      <input
        type="file"
        name={`sectionImage`}
        className='p-md-3 p-2   w-100  p-4  '
        onChange={(event) => handleSectionImageChange(event, index)}
      />
      <small  style={{color:"#808080"}}>Recommended image size: 1200px * 400px (ratio 16:9)</small>
      <input
        type="text"
        name="youtubeLink"
        placeholder='Youtube Link'
        className='p-md-3 p-2 mb-4 w-100 mt-3'
        value={section.youtubeLink}
        onChange={(event) => handleSectionChange(event, index)}
      />
    </div>
  ))
}

  
  <div>
                        <button
                        type="button"
                        className='add-more-button w-100 mb-3 d-flex flex-row justify-content-between mt-3 p-2 border '
                        onClick={handleAddMore}
                        >
                            <h6 className="font-bold ps-3 my-auto">Add more content</h6>
                            <h5 className="my-auto font-bold"><BsPlusLg/></h5>
                        </button>
                        </div>
 
       <div className="buttons mx-auto">
        <button type="submit" className=" w-50 add-author-buttor p-2">Publish</button>
       <h6 role='button' onClick={handleCancel} className='py-3 mx-auto'>Cancel</h6>
       </div> 
      </form>
    </div> 
    </div>
      </div>
      </section>
    </>
  );
};

export default EditBlogPost;

import React, { useState, useContext } from 'react';
import axios from "axios";
import './admin-login.css'
import { adminlogin } from "../../data/admin-login";
import { BsExclamationTriangle } from "react-icons/bs";

 const  OtpVerify = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);
    const [otpSent, setOtpSent] = useState(false);  

    const handleSendOTP = (event) => {
        event.preventDefault();
        setPhoneError(null);   
        setOtpSent(false);   
        axios.get(`${process.env.REACT_APP_API_URL}/sendOTP`, {
          params: {
            phonenumber: phoneNumber,
            channel: 'sms'
          }
        })
        .then(() => {
          setOtpSent(true); // Set OTP sent status to true after sending
        })
        .catch((err) => {
          // Assuming that status code 400 means incorrect phone number
          if(err.response && err.response.status === 400) {
            setPhoneError('Mobile number is wrong');
          } else {
            setPhoneError('Something went wrong');
          }
        });
      }
    
      const handleVerifyOTP =(event)=>{
        event.preventDefault();
        setOtpError(null);   
        axios.get(`${process.env.REACT_APP_API_URL}/verifyOTP`, {
          params:{
            phonenumber: phoneNumber,
            code: otp
          }
        })
        .then((response) => {
          if (response.status === 200) {
            
            window.location.href = '/adminpanel';
          }
        })
        .catch((err) => {
          // Assuming that status code 400 means incorrect OTP
          if(err.response && err.response.status === 400) {
            setOtpError('OTP is wrong');
          } else {
            setOtpError('Something went wrong');
          }
        });
      }
    
  return (
   <>
   {adminlogin.map((item)=>(
    <section className="admin-login" 
    style={{
        backgroundImage: `url(${item.background})`,
        alt: `{item.alt}`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}>    
    <div className="container p-3 p-md-5"  >
    <div className=" login-card mx-auto ">
     <img src={item.logo} alt="" className="mx-auto pb-3 pb-md-4"/> 
      <form   className="mx-auto w-100">
        <div>
          <input
            type="text"
            className="p-md-3  w-100 p-2 mb-md-2 mb-2"
            placeholder="Phone number"
            value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)}
          />
          {phoneError && <div  className="text-red-500 mt-2  flex py-2 bg-white w-10/12 border border-dasRed rounded-lg">
         <h4  className="mt-1 px-2 "> <BsExclamationTriangle/></h4> <div >{phoneError}</div>  </div>}
        </div>

        <button   className="w-100 login-button p-3 border-2 mt-1" 
        style={{background:"white", color:"#119f93", border:"2px solid #119f93"}}
        onClick={(event) => handleSendOTP(event)}>Send OTP</button>
      {otpSent && <div className="mt-1" style={{color:"#000000"}}>OTP Sent! Check your phone.</div>}

        <div>
          <input
            placeholder="Enter OTP"
            className="p-md-3 mt-4 w-100 p-2 mb-2 mt-5"
            value={otp} onChange={(event) => setOtp(event.target.value)}
          />
 {otpError && <div className="text-red-500 mb-3  flex py-2 bg-white w-10/12 border border-dasRed rounded-lg">
        <h4  className="mt-1 px-2 "> <BsExclamationTriangle/></h4> <div >{otpError}</div>  </div>}
        </div>
        <div>
        
       </div>
        <button onClick={(event) => handleVerifyOTP(event)}
 className="w-100 login-button p-3 mt-1">Login</button>
       </form>
    </div> 
    </div>
    </section>
     ))}
    </>
  );
};
export default  OtpVerify;

import React, {useState} from "react";
import axios from "axios";
 
import './authorcss.css'
import NavX from "../dashboard-nav/NavX";
import DashboardNav from "../dashboard-nav/DashboardNav";
import { BsPerson } from "react-icons/bs";
const UpdateAuthor = ({ author }) => {
 
    const [name, setName] = useState(author.name);
    const [designation, setDesignation] = useState(author.designation);
    const [picture, setPicture] = useState(null);
  
    if (!author) {
      return <div>Loading...</div>;
    }
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('name', name);
      formData.append('designation', designation);
      formData.append('profilePicture', picture);
      try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/authors/${author._id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(res.data);
        alert('Author updated successfully!');
      } catch (err) {
        console.error(err);
      }
    };
    const handleCancel = () => {
        window.location.href = '/adminpanel/authors';
    };
    return (
        <section className='d-flex flex-row authors-home'>
        <DashboardNav/>
        <div className='content-section section-padding'>
          <NavX/>
          <section className="  add-author" 
          > 
              <div className="container p-3 p-md-3">
    <div className="    add-author-card">
      <form onSubmit={handleSubmit}   className="mx-auto pt-4">
      <div  className="mx-auto add-profile-pic  "> 
     <i className=' p-2'><BsPerson/></i>
     </div>
        <input type="hidden" name="id" value={author._id} />
        <div className="form-group pt-3">
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="p-md-3 p-2 w-100  mb-md-4 mb-3"
            placeholder="Full Name"
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Designation"
            className="p-md-3 p-2 mb-4 w-100  "
            name="designation"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="file"
            name="profilePicture"
            className="py-md-3 px-md-1 px-0 p-2 mb-4 w-100"
            onChange={(e) => setPicture(e.target.files[0])}
          />
        </div>
        <div className="buttons mx-auto">
        <button type="submit" className=" w-50 add-author-buttor   p-2">Update</button>
        <h6 role='button' className='py-3 mx-auto' onClick={handleCancel}>Cancel</h6>
        </div>
      </form>
      </div>
      </div>
      </section>
      </div>
      </section>
    );
  };
  
  
            
            export default UpdateAuthor;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'

const DeleteBlogPost = () => {
    const [post, setPost] = useState({});
    const { id } = useParams();
  
    useEffect(() => {
      const fetchPost = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
        setPost(data);
      };
      fetchPost();
    }, [id]);
  
    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this post?')) {
          try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
            console.log(response);
            alert('Post deleted successfully!');
            // Redirect to the home page or another page after deleting the post
          } catch (error) {
            console.error(error);
            console.error(error.response);
            alert('Failed to delete the post.');
          }
        }
      };
      
    return (
      <div>
        <h1>{post.title}</h1>
        <p>{post.mainParagraph}</p>
        {/* Render the rest of the post content here */}
        <button onClick={handleDelete}>Delete</button>
      </div>
    );
  };
export default DeleteBlogPost

import { BsHouse, BsInbox, BsEasel, BsPencil, BsJournalText,  } from "react-icons/bs";

export const logo=[{
    logo:"./Images/dashboard/logo-green.png",
}]
export const logo2=[{
  logo:"./Images/dashboard/logo-green.png",
}]
export const dashboard_nav=[
    {
  icon:<BsHouse/>,
  text:"Home",
  onclick:"/adminpanel"
},
  {
    icon:<BsInbox/>,
    text:"Requests",
    onclick:"/adminpanel/enquiries"
  },

  {
    icon:<BsPencil/>,
    text:"Authors",
    onclick:"/adminpanel/authors"
  },
  {
    icon:<BsJournalText/>,
    text:"Blogs",
    onclick:"/adminpanel/blogs"
  },
]
export const dashboard_logout = [ 
    {

}
]
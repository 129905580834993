import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
 
import UpdateAuthor from "./UpdateAuthor";

const EditAuthor = () => {
  const [author, setAuthor] = useState(null);
  const { id } = useParams();
 
  useEffect(() => {
    const fetchAuthor = async () => {
      try {

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/authors/${id}`);
        console.log('author:', res.data); // Log the fetched author data
        setAuthor(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    
    fetchAuthor();
  }, [id]);
   

  if (!author) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      
      <UpdateAuthor author={author} />
    </div>
  );
};

export default EditAuthor;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsArrowRightShort, BsPencilSquare  } from "react-icons/bs";
 import { Link } from 'react-router-dom';

const RecentBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [redirectTo, setRedirectTo] = useState(null);
  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  
  const handleClickOpenBlogs = () => {
    setRedirectTo("/adminpanel/blogs");
  };
  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/recent`);
        setBlogs(response.data);
      } catch (error) {
        console.error('Failed to fetch enquiries:', error);
      }
    };
    fetchEnquiries();
  }, [])
  return (
    <div className='recent-card box-shadow-admin p-2 h-100'>
      <div className="d-flex justify-content-between">
<h6 className='p-2 ps-3'>Blogs</h6>
<h6 onClick={handleClickOpenBlogs}  role='button' className='p-2 pe-3'><BsArrowRightShort /></h6>
</div>
<div>
                            { blogs.length === 0 ? (
                                  <p>No recent blogs.</p>
                               ) : (
                            blogs.map((blog, index) => (
                     <div className="d-flex flex-row   my-2 py-2 px-3 " key={index}>
                        <div className="col-4 object-fit-contain h-25">
                        <img src={`${process.env.REACT_APP_API_URL}/${blog.mainBanner}`} alt="" className=' thumbnail-image col-11'/>
                        </div>
                        <div className="col-8 flex flex-col my-auto">
                        <h6 className='font-semibold'>{ blog.title}</h6>
                        <div className="d-flex flex-row justify-content-between">
                        <p className='my-auto text-dasGrayDark'>
                        {new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(blog.createdAt))}
</p>
                        
                        <Link  
                         to={`/adminpanel/blogs/${blog._id}/edit`} className='my-auto graytext'>
                        <BsPencilSquare/>
                        </Link> 
                        </div>
                        </div>
                        <div>

                        </div>
                     </div>
                     ))
                     )}
                     </div>
     </div>
  )
}

export default RecentBlogs

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
 const DeleteAuthor = ({ authorId }) => {
    const [author, setAuthor] = useState(null);
    const { id } = useParams();
  
    useEffect(() => {
      const fetchAuthor = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/authors/${id}`);
          setAuthor(res.data);
        } catch (err) {
          console.error(err);
        }
      };
      fetchAuthor();
    }, [id]);
  
    const handleDelete = async () => {
      try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/authors/${id}`);
        console.log(res.data);
        alert('Author deleted successfully!');
        window.location.href = '/adminpanel/authors';
      } catch (err) {
        console.error(err);
      }
    };
    const handleCancel = () => {
      window.location.href = '/adminpanel/authors';
    };
    if (!author) {
      return <div>Loading...</div>;
    }
  
    return (
      <div>
        <h2>Delete Author</h2>
        <p>Are you sure you want to delete {author.name}?</p>
        <button onClick={handleDelete}>Yes</button>
        <button onClick={handleCancel}>No</button>
      </div>
    );
  };
export default DeleteAuthor

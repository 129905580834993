import { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardNav from '../dashboard-nav/DashboardNav'
 import './enquiries.css'
 import {  BsChevronDown } from "react-icons/bs";
import NavX from '../dashboard-nav/NavX';
import { BsPhone, BsEnvelope } from "react-icons/bs";

const EnquiriesHome = () => {
  const [contacts, setContacts] = useState([]);
  const [openStates, setOpenStates] = useState({});  // Use an object to track open states

  const toggleOpen = (id) => {  // Takes an ID
    setOpenStates({
      ...openStates,
      [id]: !openStates[id],  // Toggle the state of the given ID
    });
  };

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contact`);
        const sortedContacts = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setContacts(sortedContacts);
      } catch (error) {
        console.error('Failed to fetch contacts.');
      }
    };
  
    fetchContacts();
  }, []);
    return (
      <section className='d-flex flex-row authors-home'>
        <DashboardNav/>
        <div className='content-section section-padding'>
          <NavX/> 
          {/** */}
          <div className="row mx-2 neg-mt ">
          {contacts.length === 0 ? (
                                  <p>No enquiries.</p>
                               ) : (
           contacts.map((contact) => (
              <div className="col-12 py-2" key={contact._id}>
                <div className="enquiries-card py-1 box-shadow-admin" onClick={() => toggleOpen(contact._id)}>  {/* Pass the ID */}
                  <div className="d-flex p-1 p-md-2 justify-content-between mx-2">
                    <div className= ''>
                      <div>
                        <p  className='bold'>{contact.name}</p>
                        <p>{contact.message}</p>
                      </div>
                      <div>
                        {/* Check the state of this specific contact */}
                        {openStates[contact._id] && (
                          <div>
                      <p>{new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(contact.createdAt))}</p>
                     <hr className=' line '/>  
                    <div className="d-flex flex-row row-gap-3 " style={{color:"#119f93"}}>
                    <div className='d-flex flex-row row-gap-3 me-5'>
                   <h1 className='  font-bold me-2' style={{fontSize:"24px"}}> <BsPhone/></h1>
                    <p className='my-auto  ' style={{fontWeight:"600"}}>{contact.contactNumber}</p>
                    </div>
                    <div className='d-flex flex-row  gap-x-2 ms-5' style={{fontWeight:"600"}}>
                   <h1 className='  me-3' style={{fontSize:"24px", fontWeight:"600"}}><BsEnvelope/></h1> 
                    <p className='my-auto '>{contact.email}</p>
                    </div>
                    </div>
                    </div> 
                        )}
                      </div>
                    </div>
                    <div className="d-flex">
                      <BsChevronDown/>
                    </div>
                  </div>
                </div>
              </div>
          ))
          )}
          </div>
        </div>
      </section>
    )
  }
  

export default EnquiriesHome
